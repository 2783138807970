import * as _genericTransformers2 from "@redis/client/dist/lib/commands/generic-transformers";
var _genericTransformers = "default" in _genericTransformers2 ? _genericTransformers2.default : _genericTransformers2;
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.transformArguments = void 0;
  const generic_transformers_1 = _genericTransformers;
  function transformArguments(index, groupId, terms, options) {
    const args = ["FT.SYNUPDATE", index, groupId];
    if (options?.SKIPINITIALSCAN) {
      args.push("SKIPINITIALSCAN");
    }
    return (0, generic_transformers_1.pushVerdictArguments)(args, terms);
  }
  exports.transformArguments = transformArguments;
  return exports;
}