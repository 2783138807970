var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.transformArguments = exports.IS_READ_ONLY = void 0;
  exports.IS_READ_ONLY = true;
  function transformArguments(index, query) {
    return ["FT.EXPLAINCLI", index, query];
  }
  exports.transformArguments = transformArguments;
  return exports;
}