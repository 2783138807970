var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.transformArguments = void 0;
  function transformArguments() {
    return ["FT._LIST"];
  }
  exports.transformArguments = transformArguments;
  return exports;
}