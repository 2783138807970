import { dew as _Dew } from ".";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.transformArguments = void 0;
  const _1 = _Dew();
  function transformArguments(index, schema) {
    const args = ["FT.ALTER", index, "SCHEMA", "ADD"];
    (0, _1.pushSchema)(args, schema);
    return args;
  }
  exports.transformArguments = transformArguments;
  return exports;
}